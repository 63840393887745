import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import SEO from "../components/seo/seo";
import Layout from "../components/layout/layout";
import { Quote, Text } from "../components/slices";
import IsVisible from "react-is-visible";
import Link from "gatsby-plugin-transition-link";
import Icon from "../components/icon/icon";

export const query = graphql`
  query ArticleQuery($uid: String) {
    prismic {
      allArticles(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            body {
              __typename
              ... on PRISMIC_ArticleBodyText {
                primary {
                  textcontent
                }
                type
              }
              ... on PRISMIC_ArticleBodyQuote {
                primary {
                  author
                  quote
                }
                type
              }
            }
            icon
            metatitle
            metadescription
            summary
            title
          }
        }
      }
    }
  }
`;

// Sort and display the different slice options
const ArticleSlices = ({ slices }) => {
  if (!slices) return null;
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.type) {
        case "text":
          return <div key={index}>{<Text slice={slice} />}</div>;
        case "quote":
          return <div key={index}>{<Quote slice={slice} />}</div>;
      }
    })();
    return res;
  });
};

// Display the title and content of the article
const ArticleBody = ({ doc }) => {
  return (
    <div className="c-content">
      <div className="container-fluid">
        <div className="row">
          <div className="c-content__container">
            <IsVisible once>
              {(isVisible) => (
                <div
                  className={`c-content__icon${isVisible ? " is-visible" : ""}`}
                >
                  <Icon icon={doc.icon}></Icon>
                </div>
              )}
            </IsVisible>
            <h1 className="c-content__title">{RichText.asText(doc.title)}</h1>
            {/* Go through the slices of the post and render the appropriate one */}
            <ArticleSlices slices={doc.body} />
          </div>
        </div>
        <div className="row">
          <div className="c-content__footer">
            <div className="c-content__footer-brand-container">
              <Link
                to="/blog"
                className="c-content__footer-brand"
                entry={{ delay: 0.5, length: 0 }}
                exit={{ length: 0.5 }}
              >
                {/* eslint-disable */}
                <svg fill="none" viewBox="0 0 60 60">
                  <path d="M60 49.0908H0v10.9091h60V49.0908z" fill="#828282" />
                  <path
                    d="M60 36.8181H0v10.9091h49.0909C55.1158 47.7272 60 42.843 60 36.8181z"
                    fill="#828282"
                  />
                  <path
                    d="M49.0909 24.5454H0c0 6.0249 4.8842 10.9091 10.9091 10.9091H60c0-6.0249-4.8842-10.9091-10.9091-10.9091z"
                    fill="#828282"
                  />
                  <path
                    d="M60 12.2727H10.9091C4.8841 12.2727 0 17.1569 0 23.1818h60V12.2727z"
                    fill="#828282"
                  />
                  <path d="M60 0H0v10.9091h60V0z" fill="#828282" />
                </svg>
                {/* eslint-enable */}
                <span className="u-visually-hide">ScootScience</span>
              </Link>
            </div>
            <div className="c-content__footer-text">
              <div>&copy; Copyright 2016 – 2022 </div>
              <div>Scoot Science Inc.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-content__close">
        <Link
          to="/"
          className="c-content__close-link c-btn"
          entry={{ delay: 0.5, length: 0 }}
          exit={{ length: 0.5 }}
        >
          <svg fill="none" viewBox="0 0 30 31">
            <path
              stroke="#fff"
              strokeWidth="2"
              d="M.9914 1.2929l28.2843 28.2843M28.9914 1.7071L.7071 29.9914"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allArticles.edges.slice(0, 1).pop();
  if (!doc) return null;
  const title = RichText.asText(doc.node.title);
  const description = RichText.asText(doc.node.summary);
  const metaTitle = doc.node.metatitle
    ? RichText.asText(doc.node.metatitle)
    : title;
  const metaDescription = doc.node.metadescription
    ? RichText.asText(doc.node.metadescription)
    : description;
  return (
    <Layout headerClass="c-navbar--article" footerClass="c-footer--article">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={`/article/${doc.node._meta.uid}`}
      />
      <ArticleBody doc={doc.node} />
    </Layout>
  );
};
