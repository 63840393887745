import React from 'react'
import { RichText } from 'prismic-reactjs'

export default ({ slice }) =>
  <blockquote className="c-content__quote-container">
    <p className="c-content__quote">{RichText.asText(slice.primary.quote)}</p>
    {slice.primary.author && (
      <footer className="c-content__quote-author">
        <p>— {RichText.asText(slice.primary.author)}</p>
      </footer>
    )}
  </blockquote>
